<template>
    <div class="container header">
        <div class="d-flex align-items-center justify-content-between ps-0">
            <div v-if="this.$route.name =='login'">
                
            </div>

            <div v-else>
                <i v-if="this.$route.name !='main' && goPath == ''" class="fal fa-chevron-left close" @click="hasHistory()?$router.go(-1):$router.push('/')"></i>
                
                <i v-if="this.$route.name !='main' && goPath != ''" class="fal fa-chevron-left close" @click="$router.push(goPath)"></i>
            </div>

            <p class="title">{{title}}</p>


            <div class="right_icon">
                <i v-if="this.$route.name=='main'" class="fal fa-bell px-2 me-3" @click="$router.push('/mypage/push');">
                    <span class="rounded-md bg-danger border-0 text-white position-absolute count" v-if="pushCount != 0"></span>
                </i>
                <i v-if="this.$route.name=='main'&&$store.state.login==true" class="fal fa-user-circle ps-2" @click="$router.push('/mypage');">
                </i>
                <button class="btn login_btn" v-else-if="this.$route.name=='main'&&$store.state.login==false" @click="$router.push('/login')">로그인</button>
            </div>

        </div>
    </div>
</template>

<script>
import { eventBus } from '../../main'
export default {
    data(){
        return{
            switch:false,
            // title:'',
            // goPath:'',
            pushCount : 1,
            login:false
        }
    },
    props:{
        title:{
            type:String
        },
        goPath:{
            type:String,
            default: ''
        }
    },
    created(){
        eventBus.$on('pageTitle',(item) => {
            this.goPath = '';
            this.title = item;
        })
        eventBus.$on('goPath',(item) => {
            this.goPath = item;
        })
    },
    methods:{
        hasHistory(){
            return window.history.length > 2
        }
    }
}
</script>

<style lang="scss" scoped>
    .header{
        max-width: 768px;
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 58px;
        background-color: #fff;
        z-index: 99;
        width: 100%;

        .login_btn{
            background-color: #d9ebff;
            padding: 3px 6px !important;
            color: cornflowerblue;
            font-size: 12px;
        }

        .title{
            position: absolute;
            font-size: 1em;
            left: 50%;
            transform: translateX(-50%);
            font-weight: bolder;
            white-space: nowrap;
        }
        >div{
            width: 100%;
            height: 100%;
            padding: 0 .5em;
        }
        i{
            font-size: 1.5em;   
        }
    }
    i.close{
        font-size: 1.2em;
        margin-right: auto;
    }

    .right_icon i{
        margin-left: auto;
        font-size: 1.28em;
        position: relative;

        .count{
            font-size: 10px;
            min-width: 6px;
            height: 6px;
            line-height: 6px;
            display: block;
            right: 4px;
            top: -2px;
            text-align: center;
        }
    }
</style>