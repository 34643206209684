<template>
    <nav class="tab_bar small w-100 d-flex justify-content-between fw-bold bg-white">
        <router-link to="/mypage/add-parking" class="w-100 text-center py-3 text-dark"><i class="fal fa-car me-2"></i>주차장 등록</router-link>
        <router-link to="/mypage/favorite" class="w-100 text-center py-3 text-dark"><i class="fal fa-star me-2"></i>즐겨찾기</router-link>
        <router-link to="/mypage/assets" class="w-100 text-center py-3 text-dark"><i class="fal fa-parking me-2"></i>내 주차권</router-link>
    </nav>
</template>

<script>
export default {
    data(){
        return{}
    },
    created(){
    },
}
</script>

<style lang="scss" scoped>
    a{
        color: #c8ccd0;
        
        &.router-link-active{
            color: #555;
        }
    }
</style>