<template>
  <div id="app" class="light">
    <div class="wrap"><router-view/></div>
  </div>
</template>

<script>
export default {
  data(){
    return{
    }
  },
}
</script>
<style src="@/assets/css/all.css"></style>
<style lang="scss">
@import 'node_modules/bootstrap/scss/bootstrap.scss';
</style>
<style src="@/assets/css/custom-bootstrap.scss" lang="scss"></style>
<style src="@/assets/css/common.scss" lang="scss"></style>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;700&display=swap');
// 숫자에 별도 폰트 적용- (눈누)프리텐다드
@font-face {
    font-family: 'Noto Sans KR';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    unicode-range: U+0020-007E;
    font-style: normal;
}
@font-face {
    font-family: 'Noto Sans KR';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff') format('woff');
    font-weight: 700;
    unicode-range: U+0020-007E;
    font-style: normal;
}
@font-face {
    font-family: 'yg-jalnan';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
.custom_font{
  font-family: 'yg-jalnan' , Helvetica, Arial, sans-serif;
}
#app {
  font-family: 'Noto Sans KR', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.header+div.container{ padding: 60px var(--bs-gutter-x, 1rem) }
.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl{
    padding-right: var(--bs-gutter-x, 1rem);
    padding-left: var(--bs-gutter-x, 1rem);
}
.wrap{
  max-width: 768px;
  margin: 0 auto;
}

ul{
  padding: 0;
  margin: 0;
}
li,ol,dl{
  list-style: none;
}
a{
  // color: #333;
}
a,a:hover,a:active{
  text-decoration: none;
  // color: #333;
}

textarea{
  resize: none;
  word-break: break-all;
  white-space: pre-line;
}

pre{
    font-family: inherit;
    white-space: pre-line;
    word-break: break-all;
}

.bg_img{
  width: 145px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.rounded-md{
  border-radius: 12px;
}
.shadow{
  box-shadow: 2px 4px 15px rgba(0, 0, 0, .15) !important;
  background-color: #fff;
}
.btn{
  margin-bottom: 0;
}

p{
  margin-bottom: 0;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
  margin-bottom: 0;
}

.h5, h5{
  font-size: 1.1rem;
}
.form-control:focus,.form-select:focus{
  border-color: #ced4da;
  box-shadow: none;
}
.btn-check:focus + .btn, .btn:focus{
  box-shadow: none;
}
.text-primary{
  // color: #0e4194 !important;
  color: #558bff !important;
}
.bg-primary{
  background-color: #558bff  !important;
}
.btn-primary{
  background-color: #558bff;
  border-color: #558bff;
}

.btn-primary:disabled, .btn-primary.disabled{
    background-color: #7095cf;
    border-color: #7095cf;
}

.text-pale{
  color: #ccc;
}
.text-grey{
  color: #aaa;
}

.bg-pale{
  background-color: #f0f7fc;
}
.bg-grey{
  background-color: #bbb;
}
.h-500{
  height: 500px;
}
// 탭메뉴 버튼
.tab_btn{
    border-bottom: 1px solid #eee;

    li{
        padding-bottom: 10px;
        width: 100%;
        text-align: center;
        color: #aaa;

        &.on{
            color: #558bff;
            border-bottom: 2px solid #558bff;
        }
    }
}

// 하단탭
.tab_bar{
    max-width: 768px;
    position: fixed;
    bottom: 0;
    left: 50%;
    border-top: 1px solid #f4f4f4;
    transform: translateX(-50%);
    background-color: #fff;
    z-index: 100; //네이버 지도 저작권표시 덮기 위해
    // border-radius: 30px 30px 0 0;

    .menus{
        height: 60px;
        font-size: 12px;

        li{
            text-align: center;

            i{
                font-size: 20px;
            }

            span{
                display: block;
                line-height: 1;
                margin-top: 3px;
            }
        }
    }
    &.transaction{
        height: 250px;
        box-shadow: 0 -5px 20px 4px #00000012;
        transform:translate(-50%,195px);
        
        &.open.open_height .t_menus_content{
            height: calc(100vh - 200px);
            overflow-y: auto;
        }
        
        &.open.open_height{
            height: calc(100vh - 120px) !important;
        }
    }
    &.transaction.open{
        transition: all 0.3s;
        transform:translate(-50%,0)
    }
    .t_menus {
        font-size: 14px;
        color: #555;
        li{
            flex-basis: 0;
            flex-grow: 1;
            text-align: center;
            padding: 10px 0;
            &.active{
                border-bottom: 2px solid ;
            }
        }
    }
    .t_menus_content{
        height: 195px;
    }
    &.open.open_height .t_menus_content{
        height: calc(100vh - 200px);
        overflow-y: auto;
    }
    .drag{
        i{
            color: #999;
            position: absolute;
            left: 50%;
            top: 0;
            transform: translate(-50%,-50%);
            background-color: #fff;
            padding: 5px 20px;
            border-radius: 4px 4px 0 0;
            border-top: 1px solid #f4f4f4;
            border-right: 1px solid #f4f4f4;
            border-left: 1px solid #f4f4f4;
        }
        position: relative;
        display: block;
        left: 0;
        top: 0;
        height: 10px;
        text-align: center;
    }
}
.guage_bar{
    width: 100%;
    height: 5px;
    background-color: #f4f4f4;
    border-radius: 4px;
    margin-bottom: 15px;
    position: relative;
    overflow: hidden;
    .guage{
        position: absolute;
        min-width: 3%;
        height: 100%;
        left: 0%;
        top: 0;
    }
}
// 메뉴 리스트 아이템, faq 아이템
.menu_list_item{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding: 16px 0;
    font-size: 15px;

    a{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    .tit{
        font-weight: 600;
    }

    .desc{
        font-weight: normal;
        line-height: 1.3;
    }

    .sub{
        .form-switch .form-check-input{
            transform: scale(1.12);
            background-size: 57%;
            background-position-x: -12%;

            &:checked{
                background-size: 64%;
                background-position-x: 120%;
            }
        }
    }
    
    .fa-chevron-right{
        color: #bbb;
    }
}


// .step_box{
//     li>span{
//         display: inline-block;
//         width: 24px;
//         border-radius: 50%;
//         background-color: #f2f6ff;
//         text-align: center;
//         color: #558bff;
//         flex-shrink: 0;
//     }
// }

.right_line{
  &::after{
    content: "";
    display: inline-block;
    width: 1px;
    height: 10px;
    background-color: #ccc;
    margin: 0 14px;
  }
}

// infoPp

.infoPp{
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;

  .dimm{
    background-color: rgba(0,0,0,0.3);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }

  .content{
    position: fixed;
    background-color: #fff;
    padding: 15px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 1px);
    max-width: 1024px;
    max-height: 75vh;
    overflow: auto;
    animation: slideUp 0.3s;
    z-index: 999;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-left: -1px;
  }

  input::placeholder{
      font-size:8px;
      color:#ddd;
  }
}

.dl{
  display: flex;
  justify-content: space-between;
  align-items: center;

  .dt{
    font-size: 13px;
    color: #aaa
  }
  .dd{
    font-size: 15px;
    font-weight: bold;
  }
}

@keyframes slideUp{
  from {
      bottom: -100%;
  }
  to {
      bottom: 0;
  }
}


.fs-xs{
  font-size: 10px;
}
.fs-sm{
  font-size: 13px;
}
.h-100{
  height: 100%;
}
.vh-100{
  height: 100vh;
}
.footer_btn{
  position: fixed;
  // bottom: 60px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
}

.light-blue-box{
    background-color: #d9ebff5c;
    padding: 1em;
    border-radius: 12px;
    color: cornflowerblue;
}

.swal2-title{
    font-size: 1.2em !important;
    margin-bottom: 1em !important;
}
.swal2-content{
    font-size: 1em !important;
}

@media screen and (min-width:768px) {
  .fs-sm{
    font-size: 18px;
  }
  .fs-xs{
    font-size: 16px;
  }
}
@media screen and (min-width:1024px) {
  .fs-sm{
    font-size: 1rem;
  }
  .fs-xs{
      font-size: 18px;
  }
}
</style>
