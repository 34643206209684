import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from "axios"
import VueElementLoading from "vue-element-loading"
import VueSimpleAlert from "vue-simple-alert"
import Vuelidate from 'vuelidate'

// import VueKakaoSdk from 'vue-kakao-sdk'
// const apiKey = '10bc54239557bb2619bc402030f3f275'
// Vue.use(VueKakaoSdk, { apiKey })

Vue.use(VueSimpleAlert)
Vue.use(Vuelidate)

Vue.component("VueElementLoading", VueElementLoading)

Vue.prototype.$http = axios
Vue.config.productionTip = false

import naver from 'vue-naver-maps';
Vue.use(naver, {
  clientID: '3wbgw5bfa1',
  useGovAPI: false, //공공 클라우드 API 사용 (선택)
  subModules:'' // 서브모듈 (선택)
});

export const eventBus = new Vue({
  methods:{
    pageTitle(name){
      this.$emit('pageTitle',name)
    },
    goPath(value){
      this.$emit('goPath',value)
    }
  }
})

var vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

window.app = vm;