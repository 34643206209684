import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    joinPath: '',
    login:false,
    loginInfo: {
      no: '',
      status:'',
      name:'',
      local:'',
      carNumber:'108 더 1749'
    },
    sideOpen : false,
  },
  mutations: {
    sideOpenFn(state, value){
      state.sideOpen = value;
    },
    login(state, value){
      state.login = true;
      state.loginInfo.no = value.no;
      // state.loginInfo.name = value.info_name
      // state.loginInfo.bank = value.info_bank
      // state.loginInfo.account = value.info_account
      ///state.loginInfo.status = value.info_status
    },
    logout(state) {
      state.login = false;
      state.loginInfo.no = '';
      // state.loginInfo.name = ''
      // state.loginInfo.bank = ''
      // state.loginInfo.account = ''
      ///state.loginInfo.status = ''
    },
    joinPath(state, join){
      state.joinPath = join;
    },
    joinOut(state){
      state.joinPath = '';
    }
  },
  actions: {
    SETLOGIN({commit}, user){
      commit('login',user)
    },
    SETLOGOUT ({commit}) {
      commit('logout')
    },
    SETJOINPATH({commit}, join){
      commit('joinPath', join)
    },
    RESETJOINPATH({commit}){
      commit('joinOut')
    }
  },
  modules: {
  }
})
