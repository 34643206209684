import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
Vue.use(VueRouter)

// Main
// const Main = () => import(/* webpackChunkName: "Main" */ '../views/Main.vue')
// const Map = () => import(/* webpackChunkName: "Main" */ '../views/map/Detail.vue')
// const Search = () => import(/* webpackChunkName: "Main" */ '../views/map/Search.vue')
// const SampleMain = () => import(/* webpackChunkName: "Main" */ '../components/main/Main.vue')

// panel
// const Complete = () => import(/* webpackChunkName: "panel" */ '../components/panel/Complete.vue')

// invest item
// const BuildingItem = () => import(/* webpackChunkName: "item" */ '../components/item/BuildingItem.vue')
// const BuildingDetail = () => import(/* webpackChunkName: "item" */ '../components/item/BuildingDetail.vue')
// const BuildingPoint = () => import(/* webpackChunkName: "item" */ '../components/item/BuildingPoint.vue')
// const BuildingDoc = () => import(/* webpackChunkName: "item" */ '../components/item/BuildingDoc.vue')
// const BuildingRental = () => import(/* webpackChunkName: "item" */ '../components/item/BuildingRental.vue')

// // notice
// const BuildingNotice = () => import(/* webpackChunkName: "notice" */ '../components/notice/BuildingNotice.vue')

// access
// const Agreement = () => import(/* webpackChunkName: "access" */ '../components/main/Agreement.vue')
// const AgreementDetail = () => import(/* webpackChunkName: "access" */ '../components/main/AgreementDetail.vue')
// const certifyM = () => import(/* webpackChunkName: "access" */ '../components/main/CertifyM.vue')
// const License = () => import(/* webpackChunkName: "access" */ '../views/mypage/License.vue')

// MyPage
// const MyPage = () => import(/* webpackChunkName: "MyPage" */ '../views/mypage/MyPage.vue')
// const Myinfo = () => import(/* webpackChunkName: "MyPage" */ '../views/mypage/Myinfo.vue')
// const Assets = () => import(/* webpackChunkName: "MyPage" */ '../views/mypage/Wallet.vue')
// const MyHistory = () => import(/* webpackChunkName: "MyPage" */ '../views/mypage/MyHistory.vue')
// const Push = () => import(/* webpackChunkName: "MyPage" */ '../views/mypage/Push.vue')
// const Privacy = () => import(/* webpackChunkName: "MyPage" */ '../views/mypage/Privacy.vue')
// const AddCar = () => import(/* webpackChunkName: "MyPage" */ '../views/mypage/AddCar.vue')
// const Setting = () => import(/* webpackChunkName: "MyPage" */ '../views/mypage/Setting.vue')
// const Favorite = () => import(/* webpackChunkName: "MyPage" */ '../views/mypage/Favorite.vue')

// CS
// const Faq = () => import(/* webpackChunkName: "CS" */ '../views/cs/Faq.vue')
// const FaqList = () => import(/* webpackChunkName: "CS" */ '../views/cs/FaqList.vue')
// const FaqDetail = () => import(/* webpackChunkName: "CS" */ '../views/cs/FaqDetail.vue')
// const Certificate = () => import(/* webpackChunkName: "CS" */ '../views/cs/Certificate.vue')
// const CertificateList = () => import(/* webpackChunkName: "CS" */ '../views/cs/CertificateList.vue')
// const Inquiry = () => import(/* webpackChunkName: "CS" */ '../views/cs/Inquiry.vue')
// const InquiryDetail = () => import(/* webpackChunkName: "CS" */ '../views/cs/InquiryDetail.vue')
// const InquiryAdd = () => import(/* webpackChunkName: "CS" */ '../views/cs/InquiryAdd.vue')
// const Notice = () => import(/* webpackChunkName: "notice" */ '../views/cs/Notice.vue')
// const NoticeDetail = () => import(/* webpackChunkName: "notice" */ '../views/cs/NoticeDetail.vue')


// Guide
// const Guide = () => import(/* webpackChunkName: "Guide" */ '../components/guide/Guide.vue')

// // Board
// const BoardMain = () => import(/* webpackChunkName: "Board" */ '../components/board/Main.vue')
// const BoardDetail = () => import(/* webpackChunkName: "Board" */ '../components/board/BoardDetail.vue')

// Sign
// const Login = () => import(/* webpackChunkName: "Login" */ '@/views/sign/Login.vue')
// const SignIn = () => import(/* webpackChunkName: "Login" */ '@/views/sign/SignIn.vue')


const routes = [
  {
    path: '/main/:location',
    name: 'Main',
    component: () => import(/* webpackChunkName: "Main" */ '../views/Main.vue')
  },
  
  {
    path: '/map/:location',
    name: 'Map',
    component: () => import(/* webpackChunkName: "Main" */ '../views/map/Detail.vue')
  },
  
  {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "Main" */ '../views/map/Search.vue')
  },

  {
    path: '/reservation/:id/:idx',
    name: 'Reservation',
    component: () => import(/* webpackChunkName: "reservation" */ '../views/reservation/Reservation.vue')
  },
  {
    path: '/receipt/:id',
    name: 'receipt',
    component: () => import(/* webpackChunkName: "reservation" */ '../views/reservation/Receipt.vue')
  },
  {
    path: '/reservation-info/:id/:idx',
    name: 'ReservationInfo',
    component: () => import(/* webpackChunkName: "reservation" */ '../views/reservation/ReservationInfo.vue')
  },
  {
    path: '/reservation-detail/:id/:idx',
    name: 'ReservationDetail',
    component: () => import(/* webpackChunkName: "reservation" */ '../views/reservation/ReservationDetail.vue')
  },
  {
    path: '/reservation-confirm/:id/:idx',
    name: 'ReservationConfirm',
    component: () => import(/* webpackChunkName: "reservation" */ '../views/reservation/ReservationConfirm.vue')
  },
  
  
  {
    path: '/mypage',
    name: 'mypage',
    component: () => import(/* webpackChunkName: "MyPage" */ '../views/mypage/MyPage.vue'),
  },
  {
    path: '/mypage/term/:idx',
    name: 'mypage-privacy',
    component: () => import(/* webpackChunkName: "MyPage" */ '../views/mypage/Privacy.vue'),
  },
  {
    path:'/license',
    name:'License',
    component:() => import(/* webpackChunkName: "access" */ '../views/mypage/License.vue')
  },
  {
    path: '/mypage/myinfo',
    name: 'mypage-myinfo',
    component: () => import(/* webpackChunkName: "MyPage" */ '../views/mypage/Myinfo.vue'),
  },
  {
    path: '/mypage/favorite',
    name: 'favorite',
    component: () => import(/* webpackChunkName: "MyPage" */ '../views/mypage/Favorite.vue'),
  },
  {
    path: '/mypage/assets',
    name: 'assets',
    component: () => import(/* webpackChunkName: "MyPage" */ '../views/mypage/Wallet.vue'),
  },
  {
    path: '/mypage/history',
    name: 'history',
    component: () => import(/* webpackChunkName: "MyPage" */ '../views/mypage/MyHistory.vue'),
  },
  {
    path: '/mypage/push',
    name: 'push',
    component: () => import(/* webpackChunkName: "MyPage" */ '../views/mypage/Push.vue'),
  },
  {
    path: '/mypage/add-car',
    name: 'add-car',
    component: () => import(/* webpackChunkName: "MyPage" */ '../views/mypage/AddCar.vue'),
  },
  {
    path: '/mypage/added-car',
    name: 'added-car',
    component: () => import(/* webpackChunkName: "MyPage" */ '../views/mypage/AddedCar.vue'),
  },
  {
    path: '/mypage/added-car/:idx',
    name: 'added-car-detail',
    component: () => import(/* webpackChunkName: "MyPage" */ '../views/mypage/AddedCarDetail.vue'),
  },
  {
    path: '/mypage/setting',
    name: 'setting',
    component: () => import(/* webpackChunkName: "MyPage" */ '../views/mypage/Setting.vue'),
  },
  {
    path: '/mypage/add-parking',
    name: 'add-parking',
    component: () => import(/* webpackChunkName: "park" */ '../views/mypage/AddParking.vue'),
  },
  {
    path: '/mypage/my-parking',
    name: 'add-parking',
    component: () => import(/* webpackChunkName: "park" */ '../views/mypage/MyParking.vue'),
  },
  
  {
    path: '/cs/faq',
    name: 'cs-faq',
    component: () => import(/* webpackChunkName: "CS" */ '../views/cs/Faq.vue'),
  },
  {
    path: '/cs/faq/detail/:idx',
    name: 'cs-faq-detail',
    component: () => import(/* webpackChunkName: "CS" */ '../views/cs/FaqDetail.vue'),
  },
  {
    path: '/cs/certificate',
    name: 'cs-certificate',
    component: () => import(/* webpackChunkName: "CS" */ '../views/cs/Certificate.vue'),
  },
  // {
  //   path: '/cs/certificate_list',
  //   name: 'cs-certificate-list',
  //   component: CertificateList,
  // },
  {
    path: '/cs/inquiry',
    name: 'cs-inquiry',
    component: () => import(/* webpackChunkName: "CS" */ '../views/cs/Inquiry.vue'),
  },
  {
    path: '/cs/inquiry/detail/:index',
    name: 'cs-inquiry-detail',
    component: () => import(/* webpackChunkName: "CS" */ '../views/cs/InquiryDetail.vue'),
  },
  {
    path: '/cs/inquiry/add',
    name: 'cs-inquiry-add',
    component: () => import(/* webpackChunkName: "CS" */ '../views/cs/InquiryAdd.vue'),
  },
  {
    path: '/cs/notice',
    name: 'notice',
    component: () => import(/* webpackChunkName: "notice" */ '../views/cs/Notice.vue'),
  },
  {
    path: '/cs/notice/:idx',
    name: 'notice_detail',
    component: () => import(/* webpackChunkName: "notice" */ '../views/cs/NoticeDetail.vue'),
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect:'/main/misa',
    children: [
      
      {
        path: '/sample-main',
        name: 'sample-main',
        component: () => import(/* webpackChunkName: "Main" */ '../components/main/Main.vue'),
      },
      // {
      //   path: '/item/:idx',
      //   name: 'building_item',
      //   component: BuildingItem,
      // },
      // {
      //   path: '/item/detail/:idx',
      //   name: 'building_detail',
      //   component: BuildingDetail,
      // },
      // {
      //   path: '/item/point/:idx',
      //   name: 'building_point',
      //   component: BuildingPoint,
      // },
      // {
      //   path: '/building-notice',
      //   name: 'building_notice',
      //   component: BuildingNotice,
      // },
      // {
      //   path: '/item/document/:idx',
      //   name: 'building_document',
      //   component: BuildingDoc,
      // },
      // {
      //   path: '/item/rental/:idx',
      //   name: 'building_rental',
      //   component: BuildingRental,
      // },
      // {
      //   path: '/certify_m',
      //   name: 'certify_mobile',
      //   component: certifyM,
      // },
      // {
      //   path: '/guide',
      //   name: 'guide',
      //   component: Guide,
      // },
      // {
      //   path: '/board-main',
      //   name: 'board-main',
      //   component: BoardMain,
      // },
      // {
      //   path: '/board/:idx',
      //   name: 'board-detail',
      //   component: BoardDetail,
      // },
      {
        path: '/complete/:idx',
        name: 'complete',
        component: () => import(/* webpackChunkName: "panel" */ '../components/panel/Complete.vue'),
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "Login" */ '@/views/sign/Login.vue')
  },
  {
    path: '/sign/signin',
    name: 'signin',
    component: () => import(/* webpackChunkName: "Login" */ '@/views/sign/SignIn.vue'),
  }, 
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import(/* webpackChunkName: "access" */ '../components/main/Agreement.vue'),
  }, {
    path: '/agreement/:idx',
    name: 'agreement_detail',
    component: () => import(/* webpackChunkName: "access" */ '../components/main/AgreementDetail.vue'),
  },
]

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { x: 0, y: 0 }
  }
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
})

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => {
		if (err.name !== 'NavigationDuplicated') throw err;
	});
};
export default router
