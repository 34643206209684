<template>
  <div>
      <router-view></router-view>
      <!-- <TabBar /> -->
  </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import TabBar from '@/components/common/TabBar.vue'


export default {
  name: 'Home',
  components: {
    Header,TabBar,
  },
}
</script>
